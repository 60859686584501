"use strict";

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function init() {
        // JavaScript to be fired on all pages
        $('.owl-carousel').owlCarousel({
          margin: 0,
          nav: true,
          items: 1
        });
        $('.mobile-toggle').click(function () {
          $('.mobile-menu').toggleClass('active');
        });

        showLoginSubmenu = function showLoginSubmenu(e) {
          e.preventDefault();
          document.querySelector('.header__content__right__login__submenu').classList.toggle('active');
        };

        show_mini_cart = function show_mini_cart() {
          document.querySelector('.widget_shopping_cart_content').classList.toggle('active');
        };

        $('.video-home__body__video').click(function () {
          if ($(this).get(0).paused) {
            $(this).get(0).play();
            $(this).next(".video-home__body__control").attr('src', '/wp-content/themes/cuisine-et-audace-new/dist/images/video-pause.svg');
          } else {
            $(this).get(0).pause();
            $(this).next(".video-home__body__control").attr('src', '/wp-content/themes/cuisine-et-audace-new/dist/images/video-play.svg');
          }
        });
      },
      finalize: function finalize() {// JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function init() {// JavaScript to be fired on the home page
      },
      finalize: function finalize() {// JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function init() {// JavaScript to be fired on the about us page
      }
    }
  }; // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event

  var UTIL = {
    fire: function fire(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
      if (fire) namespace[func][funcname](args);
    },
    loadEvents: function loadEvents() {
      // Fire common init JS
      UTIL.fire('common'); // Fire page-specific init JS, and then finalize JS

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      }); // Fire common finalize JS

      UTIL.fire('common', 'finalize');
    }
  }; // Load Events

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.